body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.text-center.userful_link.mt-5 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  flex-wrap: wrap;
}
.text-center.userful_link.mt-5 a {
  display: inline-block;
  font-size: 16px;
  margin: 12px;
  text-decoration: none;
  background: #f57a2c;
  padding: 8px 35px;
  color: #fff;
  border-radius: 50px;
  min-width: 150px;
}
.text-center.userful_link.mt-5 a:hover {
  background:#006b5e;

}
.company_name {
  color: #006b5e;
  font-size: 14px;
}
.params_wrap {
  background: #f5f5f5;
  padding: 20px;
  margin: 20px 0;
}
.text-center.userful_link a {
  display: block;
  font-size: 35px;
  padding: 20px 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.form-group {
  position: relative;
}
img.step_img {
  max-width: 100%;
}
.text_wrap {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.break_word {
  word-wrap: break-word;
}
.add_border {
  box-shadow: 0px 0px 5px #0000002e;

}
.tlh_form_wrap {
  max-width: 450px;
  margin: 0 auto;
  width: 100%;
}
#container-scroller {
  margin-top: 25%;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
  padding: 20px;
}
.demo_content {
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
  padding: 20px;
}

.login-btn {
  background: #f57a2c;
  color: #fff;
  font-size: 15px;
  border-radius: 50px;
  text-align: center;
  padding: 8px 35px;
  border:none;
}
.verification_form .form-control {
  border-radius: 50px;
  height: 47px;
  padding: 0 18px 0 47px;
}
.verification_form .form-control.email_field {
  border-radius: 50px;
  height: 47px;
  padding: 0 20px;
}
.demo_flex {
  margin-top: 8%;
}
.phone_sign {
  position: absolute;
  line-height: 47px;
  font-size: 14px;
  padding: 0 8px;
  background: #e6e6e6;
  left: 0;
  border-radius: 50% 0 0 50%;
}

.logo_wrap img {
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.loading_wrap {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  left: 0;
  top: 0;
  z-index: 2;
  gap:10px;
}
.form-group.radio_btn label {
  display: flex;
  margin: 0 10px 0;
  font-size: 12px;
}
.form-group.radio_btn {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.radio_btn input.input-radio:checked {
  /* box-shadow: 0px 0px 0px 3px #006b5e; */
  background-color: #f57a2c;

}
.radio_btn input.input-radio {
  box-shadow: 0px 0px 0px 1px #6d6d6d;
  font-size: 2em;
  width: 15px;
  height: 15px;
  margin-right: 7px;
  border: 3px solid #fff;
  background-clip: border-box;
  border-radius: 50%;
  appearance: none;
  transition: background-color 0.3s, box-shadow 0.3s;
}
.form-control::placeholder{
  color: #7A7A7A;
  font-size: 15px;
  font-style: italic;
 }
 .btn:hover {
  background:#006b5e;
 }
 a {
  color: #006b5e;
}
p.grey {
  color: #878787;
  margin-top:15px;
}
.alert {
  font-size: 14px;
  font-style: italic;
}
p.grey:before, p.grey:after{
  display: inline-block;
  content: "";
  border-top: .1px solid #878787;
  width: 15px;
  margin: 0 8px;
  transform: translateY(-4px);
}

.verification_form form.code_wrap input {
  padding: 0 ;
  margin: 0 5px;
  border-radius: 5px ;
  text-align: center;
  height:40px;
  width:40px;
  border: 1px solid #bbb;
}
.verifycode_wrap {
  justify-content: center;
}
form.code_wrap {
  /* background: #f1f1f1; */
  margin-top: 12px;
  padding: 20px 0 0;
  border-radius: 10px;
}
/* .verification_form .code_wrap .gap:nth-child(2) {
  margin-right: 40px;
} */
.trouble_wrap p, .social_wrap p {
  font-size: 12px; 
}
.trouble_wrap p {
  margin: 0;
}
.trouble_wrap a {
  text-decoration: underline;
  color: #f57a2c;
}
.trouble_wrap a:hover{

  color: #006b5e;
}
.social_wrap.pt-3 span {
  cursor: pointer;
}

.demo_wrap .form-group.login_options label {
  margin: 15px 0;
}

.login_options input[type="radio"] {
  display: none;  
}
.login_options input[type=radio]:checked + .box {
  background-color: #006b5e;
}
.login_options input[type=radio]:checked + .box span {
  color: white;
  transform: translateY(12px);
}
.login_options input[type=radio]:checked + .box span:before {
  transform: translateY(-1px);
  opacity: 1;
}
.login_options .box {
  width: 120px;
  height: 50px;
  background-color: #fff;
  transition: all 250ms ease;
  will-change: transition;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  position: relative;
  border: 2px solid #006b5e;
}

.login_options .box span {
  position: absolute;
  transform: translate(0, 15px);
  left: 0;
  right: 0;
  transition: all 300ms ease;
  font-size: 16px;
  user-select: none;
  color: #006b5e;
}
.login_options .box span:before {
  font-size: 18px;
  font-family: FontAwesome;
  display: block;
  transform: translateY(-50px);
  opacity: 0;
  transition: all 300ms ease-in-out;
  font-weight: normal;
  color: white;
}
.login_options .sms_wrap span:before {
  content: "\f2a0";
  position: absolute;
  left: 20px;
}
.login_options .email_wrap span:before {
  content: "\f0e0";
  position: absolute;
  left: 10px;
}
.login_options p {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}
.login_options p a {
  text-decoration: underline;
  font-weight: bold;
  color: #fff;
}


@media only screen and (max-width: 468px) {
  .verification_form .code_wrap .gap:nth-child(2) {
    margin-right: 0;
  }
  .verification_form form.code_wrap input {   
    margin: 0 5px !important; 
  }
}